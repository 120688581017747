import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

const initialLocalStorageModel = {
	appSettings: {
		devTools: false,
	},
	userPicks: [],
};

const SettingsPage = () => {
	const [localStorageSettings, setLocalStorageSettings] = useState({});

	const mergeDefaultsWithExistingSettings = () => {
		const appSettings = JSON.parse(localStorage.getItem('appSettings')) || {};
		const userPicks = JSON.parse(localStorage.getItem('userPicks')) || [];

		const mergedAppSettings = { ...initialLocalStorageModel.appSettings, ...appSettings };
		const mergedUserPicks = [...initialLocalStorageModel.userPicks, ...userPicks];

		return { appSettings: mergedAppSettings, userPicks: mergedUserPicks };
	};

	const getLocalStorageSettings = () => {
		let settings = { ...initialLocalStorageModel };

		if (!localStorage.getItem('appSettings')) {
			localStorage.setItem('appSettings', JSON.stringify(initialLocalStorageModel.appSettings));
		} else {
			settings.appSettings = JSON.parse(localStorage.getItem('appSettings'));
		}

		if (!localStorage.getItem('userPicks')) {
			localStorage.setItem('userPicks', JSON.stringify(initialLocalStorageModel.userPicks));
		} else {
			settings.userPicks = JSON.parse(localStorage.getItem('userPicks'));
		}

		Object.keys(localStorage).forEach((key) => {
			if (!settings[key]) {
				try {
					settings[key] = JSON.parse(localStorage.getItem(key));
				} catch (e) {
					settings[key] = localStorage.getItem(key);
				}
			}
		});

		setLocalStorageSettings(settings);
	};

	useEffect(() => {
		getLocalStorageSettings();
	}, []);

	const saveSetting = (key, value) => {
		let appSettings = JSON.parse(localStorage.getItem('appSettings')) || initialLocalStorageModel.appSettings;
		let userPicks = JSON.parse(localStorage.getItem('userPicks')) || initialLocalStorageModel.userPicks;

		if (key === 'appSettings') {
			appSettings = value;
			localStorage.setItem('appSettings', JSON.stringify(appSettings));
		} else if (key === 'userPicks') {
			userPicks = value;
			localStorage.setItem('userPicks', JSON.stringify(userPicks));
		}

		setLocalStorageSettings({ appSettings, userPicks });
	};

	const removeSetting = (key) => {
		if (key === 'appSettings') {
			localStorage.removeItem('appSettings');
		} else if (key === 'userPicks') {
			localStorage.removeItem('userPicks');
		}
		getLocalStorageSettings();
	};

	const clearAllSettings = () => {
		localStorage.clear();
		getLocalStorageSettings();
	};

	const handleEdit = ({ updated_src }) => {
		if (!updated_src || typeof updated_src !== 'object') {
			return;
		}

		setLocalStorageSettings(updated_src);

		Object.keys(updated_src).forEach((key) => {
			saveSetting(key, updated_src[key]);
		});
	};

	const handleAdd = ({ name, new_value }) => {
		if (name === 'appSettings') {
			const updatedAppSettings = { ...localStorageSettings.appSettings, ...new_value };
			saveSetting('appSettings', updatedAppSettings);
		} else if (name === 'userPicks') {
			const updatedUserPicks = [...localStorageSettings.userPicks, new_value];
			saveSetting('userPicks', updatedUserPicks);
		}
	};

	const handleDelete = ({ name }) => {
		removeSetting(name);
	};

	const toggleDevTools = (e) => {
		const newValue = e.target.checked;
		const appSettings = JSON.parse(localStorage.getItem('appSettings')) || initialLocalStorageModel.appSettings;
		appSettings.devTools = newValue;
		localStorage.setItem('appSettings', JSON.stringify(appSettings));
		setLocalStorageSettings((prevSettings) => ({
			...prevSettings,
			appSettings: {
				...prevSettings.appSettings,
				devTools: newValue,
			},
		}));
	};

	return (
		<div className='settings-page'>
			<Container fluid className='mx-0 px-0'>
				<Row>
					<Col lg={12} className='mb-3'>
						<div className='rounded p-10' style={{ XboxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
							<div className='d-flex justify-content-between flex-wrap ug-3 mb-10'>
								<h3 className='fs-md fw-600'>Local Storage</h3>
								<Button variant='danger' size='sm' onClick={clearAllSettings} className='fs-sm px-2 py-0 rounded-1'>
									Clear Storage
								</Button>
							</div>
							<Row>
								<Col>
									<div className='mb-3'>
										<Form.Check
											type='switch'
											id='devToolsSwitch'
											label='Enable Dev Tools'
											checked={localStorageSettings.appSettings?.devTools || false}
											onChange={toggleDevTools}
										/>
									</div>

									<JsonView
										src={localStorageSettings}
										editable={{
											add: true,
											edit: true,
											delete: true,
										}}
										onEdit={handleEdit}
										onAdd={handleAdd}
										onDelete={handleDelete}
										theme='github'
										enableClipboard={false}
										displaySize={false}
										collapsed={(keyPath) => keyPath.length === 2}
									/>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SettingsPage;
