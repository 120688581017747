import { v4 as uuidv4 } from 'uuid';
import * as espnHelpers from '../helper/espnHelpers';

const getApiUrl = () => {
	return process.env.NODE_ENV === 'production'
		? 'https://ogzaniacpicks.com' // AWS EC2 endpoint
		: 'http://localhost:5000'; // Local dev environment

	// return 'http://3.133.116.96:5000';
	// return 'https://ogzaniacpicks.com';
};

const apiUrl = getApiUrl();
console.log('API URL SET TO /n', apiUrl);

// HELPER FUNCTION TO FETCH DATA
const fetchData = (endpoint) =>
	fetch(`${apiUrl}${endpoint}`)
		.then((response) => {
			if (!response.ok) throw new Error('Network response was not ok');
			return response.json();
		})
		.catch((error) => {
			console.error(`Error fetching data from ${endpoint}:`, error);
			throw error;
		});

// SCRAPE FUNCTIONS

const scrapeNFLPicks = async (filters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex']) => {
	try {
		// Conditional scraping based on the filters passed
		const promises = [];

		if (filters.includes('oddstrader')) {
			promises.push(scrapeOddstrader('nfl', 'picks'));
		}
		if (filters.includes('covers')) {
			promises.push(scrapeCovers('nfl', 'picks'));
		}
		if (filters.includes('pickswise')) {
			promises.push(scrapePickswise('nfl', 'picks'));
		}
		if (filters.includes('dunkelIndex')) {
			promises.push(scrapeDunkelIndex('nfl', 'picks'));
		}

		// Resolve all the promises in parallel
		const [oddstraderData, coversData, pickswiseData, dunkelIndexData] = await Promise.all(promises);

		// Return the combined data
		return {
			oddstrader: oddstraderData || null,
			covers: coversData || null,
			pickswise: pickswiseData || null,
			dunkelIndex: dunkelIndexData || null,
		};
	} catch (error) {
		console.error('Error scraping all NFL picks:', error);
		throw error;
	}
};

const scrapeCFBPicks = async (filters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex']) => {
	try {
		// Conditional scraping based on the filters passed
		const promises = [];

		if (filters.includes('oddstrader')) {
			promises.push(scrapeOddstrader('cfb', 'picks'));
		}
		if (filters.includes('covers')) {
			promises.push(scrapeCovers('cfb', 'picks'));
		}
		if (filters.includes('pickswise')) {
			promises.push(scrapePickswise('cfb', 'picks'));
		}
		if (filters.includes('dunkelIndex')) {
			promises.push(scrapeDunkelIndex('cfb', 'picks'));
		}

		// Resolve all the promises in parallel
		const [oddstraderData, coversData, pickswiseData, dunkelIndexData] = await Promise.all(promises);

		// Return the combined data
		const data = {
			oddstrader: oddstraderData || null,
			covers: coversData || null,
			pickswise: pickswiseData || null,
			dunkelIndex: dunkelIndexData || null,
		};

		console.log('scrapeCFBPicks \n', data);

		return data;
	} catch (error) {
		console.error('Error scraping all CFB picks:', error);
		throw error;
	}
};

const links = {
	oddstrader: {
		home: 'https://oddstrader.com',
		nfl: {
			odds: 'https://oddstrader.com/nfl',
			picks: 'https://oddstrader.com/nfl/picks',
		},
		cfb: {
			odds: 'https://oddstrader.com/ncaa-college-football',
			picks: 'https://oddstrader.com/ncaa-college-football/picks',
		},
	},
	covers: {
		home: 'https://www.covers.com',
		nfl: {
			odds: 'https://www.covers.com/sport/football/nfl/odds',
			picks: 'https://www.covers.com/picks/nfl',
		},
		cfb: {
			odds: 'https://www.covers.com/sport/football/ncaaf/odds',
			picks: 'https://www.covers.com/picks/ncaaf',
		},
	},
	pickswise: {
		home: 'https://www.pickswise.com',
		nfl: {
			picks: 'https://www.pickswise.com/nfl/picks/',
		},
		cfb: {
			picks: 'https://www.pickswise.com/college-football/picks/',
		},
	},
	dunkelIndex: {
		home: 'https://www.dunkelindex.com',
		picks: 'https://www.dunkelindex.com/picks',
		nflPicks: 'https://www.dunkelindex.com/picks/nfl',
		cfbPicks: 'https://www.dunkelindex.com/picks/fbs',
	},
};

const nflTeams = [
	{
		teamName: 'Buffalo Bills',
		teamCity: 'Buffalo',
		teamMascot: 'Bills',
		teamConference: 'AFC East',
		teamAbbreviation: 'BUF',
	},
	{
		teamName: 'Miami Dolphins',
		teamCity: 'Miami',
		teamMascot: 'Dolphins',
		teamConference: 'AFC East',
		teamAbbreviation: 'MIA',
	},
	{
		teamName: 'New England Patriots',
		teamCity: 'New England',
		teamMascot: 'Patriots',
		teamConference: 'AFC East',
		teamAbbreviation: 'NE',
	},
	{
		teamName: 'New York Jets',
		teamCity: 'New York',
		teamMascot: 'Jets',
		teamConference: 'AFC East',
		teamAbbreviation: 'NYJ',
	},
	{
		teamName: 'Baltimore Ravens',
		teamCity: 'Baltimore',
		teamMascot: 'Ravens',
		teamConference: 'AFC North',
		teamAbbreviation: 'BAL',
	},
	{
		teamName: 'Cincinnati Bengals',
		teamCity: 'Cincinnati',
		teamMascot: 'Bengals',
		teamConference: 'AFC North',
		teamAbbreviation: 'CIN',
	},
	{
		teamName: 'Cleveland Browns',
		teamCity: 'Cleveland',
		teamMascot: 'Browns',
		teamConference: 'AFC North',
		teamAbbreviation: 'CLE',
	},
	{
		teamName: 'Pittsburgh Steelers',
		teamCity: 'Pittsburgh',
		teamMascot: 'Steelers',
		teamConference: 'AFC North',
		teamAbbreviation: 'PIT',
	},
	{
		teamName: 'Houston Texans',
		teamCity: 'Houston',
		teamMascot: 'Texans',
		teamConference: 'AFC South',
		teamAbbreviation: 'HOU',
	},
	{
		teamName: 'Indianapolis Colts',
		teamCity: 'Indianapolis',
		teamMascot: 'Colts',
		teamConference: 'AFC South',
		teamAbbreviation: 'IND',
	},
	{
		teamName: 'Jacksonville Jaguars',
		teamCity: 'Jacksonville',
		teamMascot: 'Jaguars',
		teamConference: 'AFC South',
		teamAbbreviation: 'JAX',
	},
	{
		teamName: 'Tennessee Titans',
		teamCity: 'Tennessee',
		teamMascot: 'Titans',
		teamConference: 'AFC South',
		teamAbbreviation: 'TEN',
	},
	{
		teamName: 'Denver Broncos',
		teamCity: 'Denver',
		teamMascot: 'Broncos',
		teamConference: 'AFC West',
		teamAbbreviation: 'DEN',
	},
	{
		teamName: 'Kansas City Chiefs',
		teamCity: 'Kansas City',
		teamMascot: 'Chiefs',
		teamConference: 'AFC West',
		teamAbbreviation: 'KC',
	},
	{
		teamName: 'Las Vegas Raiders',
		teamCity: 'Las Vegas',
		teamMascot: 'Raiders',
		teamConference: 'AFC West',
		teamAbbreviation: 'LV',
	},
	{
		teamName: 'Los Angeles Chargers',
		teamCity: 'Los Angeles',
		teamMascot: 'Chargers',
		teamConference: 'AFC West',
		teamAbbreviation: 'LAC',
	},
	{
		teamName: 'Dallas Cowboys',
		teamCity: 'Dallas',
		teamMascot: 'Cowboys',
		teamConference: 'NFC East',
		teamAbbreviation: 'DAL',
	},
	{
		teamName: 'New York Giants',
		teamCity: 'New York',
		teamMascot: 'Giants',
		teamConference: 'NFC East',
		teamAbbreviation: 'NYG',
	},
	{
		teamName: 'Philadelphia Eagles',
		teamCity: 'Philadelphia',
		teamMascot: 'Eagles',
		teamConference: 'NFC East',
		teamAbbreviation: 'PHI',
	},
	{
		teamName: 'Washington Commanders',
		teamCity: 'Washington',
		teamMascot: 'Commanders',
		teamConference: 'NFC East',
		teamAbbreviation: 'WAS',
	},
	{
		teamName: 'Chicago Bears',
		teamCity: 'Chicago',
		teamMascot: 'Bears',
		teamConference: 'NFC North',
		teamAbbreviation: 'CHI',
	},
	{
		teamName: 'Detroit Lions',
		teamCity: 'Detroit',
		teamMascot: 'Lions',
		teamConference: 'NFC North',
		teamAbbreviation: 'DET',
	},
	{
		teamName: 'Green Bay Packers',
		teamCity: 'Green Bay',
		teamMascot: 'Packers',
		teamConference: 'NFC North',
		teamAbbreviation: 'GB',
	},
	{
		teamName: 'Minnesota Vikings',
		teamCity: 'Minnesota',
		teamMascot: 'Vikings',
		teamConference: 'NFC North',
		teamAbbreviation: 'MIN',
	},
	{
		teamName: 'Atlanta Falcons',
		teamCity: 'Atlanta',
		teamMascot: 'Falcons',
		teamConference: 'NFC South',
		teamAbbreviation: 'ATL',
	},
	{
		teamName: 'Carolina Panthers',
		teamCity: 'Carolina',
		teamMascot: 'Panthers',
		teamConference: 'NFC South',
		teamAbbreviation: 'CAR',
	},
	{
		teamName: 'New Orleans Saints',
		teamCity: 'New Orleans',
		teamMascot: 'Saints',
		teamConference: 'NFC South',
		teamAbbreviation: 'NO',
	},
	{
		teamName: 'Tampa Bay Buccaneers',
		teamCity: 'Tampa Bay',
		teamMascot: 'Buccaneers',
		teamConference: 'NFC South',
		teamAbbreviation: 'TB',
	},
	{
		teamName: 'Arizona Cardinals',
		teamCity: 'Arizona',
		teamMascot: 'Cardinals',
		teamConference: 'NFC West',
		teamAbbreviation: 'ARI',
	},
	{
		teamName: 'Los Angeles Rams',
		teamCity: 'Los Angeles',
		teamMascot: 'Rams',
		teamConference: 'NFC West',
		teamAbbreviation: 'LAR',
	},
	{
		teamName: 'San Francisco 49ers',
		teamCity: 'San Francisco',
		teamMascot: '49ers',
		teamConference: 'NFC West',
		teamAbbreviation: 'SF',
	},
	{
		teamName: 'Seattle Seahawks',
		teamCity: 'Seattle',
		teamMascot: 'Seahawks',
		teamConference: 'NFC West',
		teamAbbreviation: 'SEA',
	},
];

const teamAliases = {
	'washington football team': 'WAS',
	'washington commanders': 'WAS',
	'los angeles rams': 'LAR',
	'los angeles chargers': 'LAC',
	'new york giants': 'NYG',
	'new york jets': 'NYJ',
	'kansas city chiefs': 'KC',
	arz: 'ARI',
};

const scrapeOddstrader = (leagueType, dataType) => {
	const data = fetchData(`/api/scrape/${leagueType}/oddstrader/${dataType}`);
	return data;
};

const scrapeCovers = (leagueType, dataType) => {
	const picksData = fetchData(`/api/scrape/${leagueType}/covers/${dataType}`);
	return picksData;
};

const scrapePickswise = (leagueType, dataType) => {
	const data = fetchData(`/api/scrape/${leagueType}/pickswise/${dataType}`);
	return data;
};

const scrapeDunkelIndex = (leagueType, dataType) => {
	const data = fetchData(`/api/scrape/${leagueType}/dunkelIndex/${dataType}`);
	return data;
};

const getNFLEventsWithPicks = async () => {
	try {
		const events = await espnHelpers.getNFLEvents();
		const formattedPicks = await getFormattedNFLPicks();

		// console.log('getNFLEvents() \n', events);

		const eventsWithPicks = await Promise.all(
			events.map(async (event) => {
				const eventStatus = await getNFLEventStatusByEventId(event.id);

				const eventTeams = event.competitors.map((team) => ({
					name: team.name.toLowerCase(),
					abbreviation: getTeamAbbreviation(team.name),
					mascot: nflTeams.find((t) => t.teamName.toLowerCase() === team.name.toLowerCase())?.teamMascot.toLowerCase(),
				}));

				const picks = {};

				for (const source in formattedPicks) {
					picks[source] = formattedPicks[source].filter((pick) => {
						const pickTeams = pick.matchupAbbrId.split('-');

						// Check if both teams in the pick match any of the event teams
						return pickTeams.every((pickTeamAbbr) => eventTeams.some((eventTeam) => eventTeam.abbreviation === pickTeamAbbr));
					});
				}

				return {
					...event,
					eventStatus,
					picks,
				};
			})
		);

		return eventsWithPicks;
	} catch (error) {
		console.error('Error in getNFLEventsWithPicks:', error);
		throw error;
	}
};

const getFormattedNFLPicks = async () => {
	try {
		const filters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex'];
		const scrapedPicks = await scrapeNFLPicks(filters);

		const formattedData = {
			oddstrader: scrapedPicks.oddstrader
				.map((pick) => formatPickData(pick, 'Oddstrader', 'https://oddstrader.com'))
				.filter(Boolean),
			covers: scrapedPicks.covers.map((pick) => formatPickData(pick, 'Covers', 'https://covers.com')).filter(Boolean),
			pickswise: scrapedPicks.pickswise.map((pick) => formatPickData(pick, 'Pickswise', 'https://pickswise.com')).filter(Boolean),
			dunkelIndex: scrapedPicks.dunkelIndex
				.map((pick) => formatPickData(pick, 'DunkelIndex', 'https://dunkelindex.com'))
				.filter(Boolean),
		};

		return formattedData;
	} catch (error) {
		console.error('Error in getFormattedNFLPicks:', error);
		throw error;
	}
};

const formatPickData = (pick, sourceName, sourceURL) => {
	let awayTeamAbbr, homeTeamAbbr, pickValue, pickDescription;

	switch (sourceName.toLowerCase()) {
		case 'oddstrader':
			awayTeamAbbr = getTeamAbbreviation(pick.team1.name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2.name);
			pickValue = {
				team1: {
					label: `${pick.team1.name}`,
					value: `Spread ${pick.team1.consensusSpread} | ${pick.team1.consensusTotal} | ${pick.team1.aiPredictionScore} pts`,
				},
				team2: {
					label: `${pick.team2.name}`,
					value: `Spread ${pick.team2.consensusSpread} | ${pick.team2.consensusTotal} | ${pick.team2.aiPredictionScore} pts`,
				},
			};
			pickDescription = `Consensus Spread: ${pick.consensusSpread}, Consensus Total: ${pick.consensusTotal}`;
			break;

		case 'covers':
			awayTeamAbbr = getTeamAbbreviation(pick.team1Name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2Name);
			pickValue = pick.pickValueText || 'No value';
			pickDescription = pick.pickDescription || 'No description';
			break;

		case 'pickswise':
			awayTeamAbbr = getTeamAbbreviation(pick.team1Name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2Name);
			pickValue = {
				confidence: pick.pickConfidence,
				type: pick.pickType,
				value: pick.pickOutcome,
			};
			pickDescription = pick.reasoning;
			break;

		case 'dunkelindex':
			awayTeamAbbr = getTeamAbbreviation(pick.team1Name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2Name);
			pickValue = {
				isFeaturedPick: pick.featuredPickInformation ? true : false,
				featuredPickInformation: pick.featuredPickInformation || 'No description',
				dunkelRawText: pick.predictions.dunkel?.rawText || 'No value',
				dunkelTotalScore: pick.predictions.dunkel?.totalScore || 'No value',
				dunkelWinningMargin: pick.predictions.dunkel?.winningMargin || 'No value',
				vegasRawText: pick.predictions.vegas?.rawText || 'No value',
				vegasTotalScore: pick.predictions.vegas?.totalScore || 'No value',
				vegasWinningMargin: pick.predictions.vegas?.winningMargin || 'No value',
			};
			pickDescription = pick.featuredPickInformation || 'No description';
			break;

		default:
			awayTeamAbbr = 'n/a';
			homeTeamAbbr = 'n/a';
			pickValue = 'No value';
			pickDescription = 'No description';
			break;
	}

	// If team abbreviations couldn't be found, skip this pick
	if (!awayTeamAbbr || !homeTeamAbbr) {
		console.warn(`Could not find team abbreviations for pick:`, pick);
		return null;
	}

	return {
		id: uuidv4(),
		matchupAbbrId: `${awayTeamAbbr}-${homeTeamAbbr}`,
		pickSourceName: sourceName,
		pickSourceURL: pick.pickSourceURL || '',
		pickType: pick.pickType || '',
		pickValue,
		pickDescription,
		pickConfidence: pick.pickConfidence || '',
	};
};

const teamAbbreviationMap = {};

const cityAbbreviations = {
	'new york': ['ny', 'n.y.'],
	'los angeles': ['la', 'l.a.'],
	'san francisco': ['sf'],
	'kansas city': ['kc'],
	'tampa bay': ['tb'],
	'new england': ['ne'],
	'green bay': ['gb'],
};

nflTeams.forEach((team) => {
	const variants = [];

	// Full team name
	variants.push(team.teamName.toLowerCase());

	// Team city
	variants.push(team.teamCity.toLowerCase());

	// Team mascot
	variants.push(team.teamMascot.toLowerCase());

	// Team abbreviation
	variants.push(team.teamAbbreviation.toLowerCase());

	// City abbreviations with mascot
	const cityAbbrs = cityAbbreviations[team.teamCity.toLowerCase()];
	if (cityAbbrs) {
		cityAbbrs.forEach((abbr) => {
			variants.push(`${abbr} ${team.teamMascot.toLowerCase()}`); // e.g., 'ny jets'
			variants.push(`${abbr}.${team.teamMascot.toLowerCase()}`); // e.g., 'n.y.jets'
			variants.push(`${abbr}${team.teamMascot.toLowerCase()}`); // e.g., 'nyjets'
			variants.push(abbr); // e.g., 'ny', 'n.y.'
		});
	}

	// Special case for Arizona Cardinals to include 'arz'
	if (team.teamName.toLowerCase() === 'arizona cardinals') {
		variants.push('arz'); // Add 'arz' as a variant
	}

	// Remove periods and spaces in variants
	const normalizedVariants = variants.map((v) => v.replace(/\./g, '').replace(/\s+/g, '').trim());

	normalizedVariants.forEach((variant) => {
		teamAbbreviationMap[variant] = team.teamAbbreviation.toLowerCase();
	});
});

const getTeamAbbreviation = (teamName) => {
	if (!teamName || typeof teamName !== 'string') return null;

	const normalizedTeamName = teamName.toLowerCase().trim().replace(/\./g, '').replace(/\s+/g, '');

	// Check in teamAliases first
	if (teamAliases[normalizedTeamName]) {
		return teamAliases[normalizedTeamName].toLowerCase();
	}

	if (teamAbbreviationMap[normalizedTeamName]) {
		return teamAbbreviationMap[normalizedTeamName].toLowerCase();
	}

	console.warn(`Team abbreviation not found for team name: ${teamName}`);
	return null;
};

const getNFLEventStatusByEventId = async (eventId) => {
	const scoresFromESPN = `https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard`;
	const res = await fetch(scoresFromESPN);
	const data = await res.json();

	const targetEvent = data.events.find((event) => event.id === eventId);

	const status = {
		name: targetEvent?.status?.type?.name || '',
		state: targetEvent?.status?.type?.state || '',
		shortDetail: targetEvent?.status?.type?.shortDetail || '',
		completed: targetEvent?.status?.type?.completed || false,
	};

	return status;
};

export {
	scrapeNFLPicks,
	scrapeCFBPicks,
	scrapeOddstrader,
	scrapeCovers,
	scrapePickswise,
	scrapeDunkelIndex,
	getNFLEventsWithPicks,
};
