import React, { useEffect, useState, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import { NavLink, useLocation } from 'react-router-dom';
import { TbArrowAutofitWidth, TbBrandBootstrap } from 'react-icons/tb'; // Import the icons

// COMPONENTS
import HeaderNav from '../components/HeaderNav';
import SidebarNav from '../components/SidebarNav';
import CustomBlock from '../components/CustomBlock';
import LoadingOverlay from '../components/LoadingOverlay';
import NavBar from '../components/NavBar';

const Page = (props) => {
	const { appInfo, userInfo, children } = props;
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [menuItems, setMenuItems] = useState([]);
	const [currentRoute, setCurrentRoute] = useState([]);
	const [devScreenWidth, setDevScreenWidth] = useState(window.innerWidth);
	const [devBootstrapBreakpoint, setDevBootstrapBreakpoint] = useState('');

	useEffect(() => {
		const updateScreenInfo = () => {
			const width = window.innerWidth;
			setDevScreenWidth(width);

			let breakpoint = '';
			if (width < 576) {
				breakpoint = 'xs';
			} else if (width >= 576 && width < 768) {
				breakpoint = 'sm';
			} else if (width >= 768 && width < 992) {
				breakpoint = 'md';
			} else if (width >= 992 && width < 1200) {
				breakpoint = 'lg';
			} else if (width >= 1200 && width < 1400) {
				breakpoint = 'xl';
			} else {
				breakpoint = 'xxl';
			}
			setDevBootstrapBreakpoint(breakpoint);
		};

		updateScreenInfo();
		window.addEventListener('resize', updateScreenInfo);

		return () => {
			window.removeEventListener('resize', updateScreenInfo);
		};
	}, []);

	// LOAD MENU ITEMS
	useEffect(() => {
		if (appInfo) {
			setMenuItems({
				main: appInfo.routes || [],
				sportsBetting: appInfo.sportsBettingRoutes || [],
				management: appInfo.managementRoutes || [],
			});
		}
	}, [appInfo]);

	// SET CURRENT ROUTE BASED ON LOCATION
	useEffect(() => {
		if (location) {
			const currentLocationRouteSettings = appInfo.allRoutes.filter(
				(route) => route.path === location.pathname
			);
			setCurrentRoute(currentLocationRouteSettings[0] || null);
		}
	}, [location]);

	// Check if devTools is enabled in localStorage
	const isDevToolsEnabled = JSON.parse(localStorage.getItem('appSettings'))?.devTools;

	return (
		<>
			{!menuItems ? (
				<LoadingOverlay />
			) : (
				<div className='page d-flex'>
					{/* <SidebarNav menuItems={menuItems} /> */}

					<div className='main position-relative'>
						<HeaderNav
							appInfo={appInfo}
							menuItems={menuItems}
							currentRoute={currentRoute}
							userInfo={userInfo}
						/>
						<div className='page-main'>
							{isLoading && <LoadingOverlay />}
							{children}
						</div>
						<NavBar menuItems={menuItems} />
					</div>

					{/* Show the screen info if devTools is enabled */}
					{isDevToolsEnabled && (
						<div
							className='mt-auto d-flex align-items-center gap-2 m-10'
							style={{
								position: 'fixed',
								bottom: '100px',
								right: '0',
								zIndex: '10',
							}}
						>
							<div
								className='d-flex align-items-center gap-6 bg-dark bg-gradient rounded-pill px-2 py-1 fit-content'
								style={{
									boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
								}}
							>
								<TbArrowAutofitWidth className='text-light' size={20} />
								<p className='fs-xs text-light fw-500'>{devScreenWidth} px</p>
							</div>
							<div
								className='d-flex align-items-center gap-6 bg-dark bg-gradient rounded-pill px-2 py-1 fit-content'
								style={{
									boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
								}}
							>
								<TbBrandBootstrap className='text-light' size={22} />
								<p className='fs-xs text-light fw-500'>{devBootstrapBreakpoint}</p>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default Page;
