import * as scrapeService from './scrapeService';
import { v4 as uuidv4 } from 'uuid';

// ESPN HIDDEN API ENDPOINTS - https://gist.github.com/akeaswaran/b48b02f1c94f873c6655e7129910fc3b

const espnAPI = {
	cfb: {
		latestNews: 'http://site.api.espn.com/apis/site/v2/sports/football/college-football/news',
		latestScores: 'http://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard',
		gameInformation: 'http://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=:gameId',
		teamInformation: 'http://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/:team',
		rankings: 'http://site.api.espn.com/apis/site/v2/sports/football/college-football/rankings',
	},
};

const cfbTeamsRef = [];
const cfbTeamsRef_simple = [];

// const getCFBTeamProfileByAbbreviation = async (abbr) => {
// 	const cfbTeamsList = await getCFBTeamsFromESPN();
// 	const simplifiedCFBTeamsRef = [];

// 	teams.map((team) => {
// 		const teamProfileModel = {
// 			espn_id: team.teamProfile.espn_id,
// 			espn_uid: team.teamProfile.espn_uid,
// 			espn_slug: team.teamProfile.espn_slug,
// 			logo: team.teamProfile.logo,
// 			name: team.teamProfile.teamProfile.name,
// 			nickname: team.teamProfile.teamProfile.nickname,
// 			abbreviation: team.teamProfile.teamProfile.abbreviation,
// 		};

// 		simplifiedCFBTeamsRef.push(teamProfileModel);
// 	});
// };

// GET CFB TEAMS FUNCTION
export const getCFBTeams = async () => {
	const api = espnAPI.cfb.rankings;
	const response = await fetch(api);
	const data = await response.json();

	// COLLECTION ARRAY TO STORE UNIQUE TEAM IDS
	const teamIds = [];
	const teamIdToRankings = {};

	(data.rankings || []).forEach((ranking) => {
		(ranking.ranks || []).forEach(({ team = {} }) => {
			if (!teamIds.includes(team.id)) {
				teamIds.push(team.id);
			}
			if (!teamIdToRankings[team.id]) {
				teamIdToRankings[team.id] = [];
			}
			teamIdToRankings[team.id].push(ranking);
		});
	});

	// FETCH TEAM INFORMATION FOR EACH TEAM ID
	const teams = await Promise.all(
		teamIds.map(async (teamId) => {
			const teamInfoAPI = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${teamId}`;
			const response = await fetch(teamInfoAPI);
			const teamData = await response.json();

			const team = teamData.team || {};
			const teamProfile = {
				espn_slug: `${team.location} ${team.name}`
					.toLowerCase()
					.replace(/[^\w\s-]/g, '')
					.replace(/\s+/g, '-'),
				espn_id: team.id || '',
				espn_uid: team.uid || '',
				espn_teamInformationAPI: teamInfoAPI,
				name: team.name || '',
				logo: team.logos?.[0]?.href || '',
				teamProfile: {
					name: team.name || '',
					nickname: team.nickname || '',
					abbreviation: team.abbreviation || '',
					location: team.location || '',
					color: `#${team.color?.toUpperCase() || ''}`,
					logos: (team.logos || []).reduce((acc, logo) => {
						if (logo.rel.includes('default')) {
							acc.default = logo.href;
						} else if (logo.rel.includes('dark')) {
							acc.dark = logo.href;
						} else if (logo.rel.includes('light')) {
							acc.light = logo.href;
						} else {
							acc.other = acc.other || [];
							acc.other.push(logo.href);
						}
						return acc;
					}, {}),
				},
				rankings: teamIdToRankings[team.id] || [],
				identifiers: [
					team.abbreviation.toLowerCase(),
					team.name.toLowerCase(),
					team.nickname.toLowerCase(),
					team.location.toLowerCase(),
				],
				links: (team.links || []).map(({ href = '', text = '' }) => ({
					url: href,
					label: text,
				})),
			};

			return { teamProfile, nextEvent: teamData.team?.nextEvent || [] };
		})
	);

	setSimplifiedTeamsData(teams);

	cfbTeamsRef.push(teams);

	console.log('getCFBTeams \n', teams);

	// CREATE TEAM ID TO TEAM DATA MAPPING
	const teamIdToTeamData = {};
	const teamsData = teams.map((t) => {
		teamIdToTeamData[t.teamProfile.espn_id] = t.teamProfile;
		return t.teamProfile;
	});

	// COLLECT ALL NEXT EVENTS AND ENSURE THEY ARE UNIQUE
	const allNextEvents = teams.flatMap((t) => t.nextEvent);
	const matchups = [];
	allNextEvents.forEach((event) => {
		if (!matchups.some((match) => match.id === event.id)) {
			matchups.push(event);
		}
	});

	// ENRICH EACH COMPETITOR IN THE MATCHUPS
	const enrichedMatchups = matchups.map((event) => {
		const competitors = event.competitions?.[0]?.competitors || [];

		// ASSUME FIRST COMPETITOR IS AWAY TEAM AND SECOND IS HOME TEAM
		const awayTeam = competitors.find((comp) => comp.homeAway === 'away');
		const homeTeam = competitors.find((comp) => comp.homeAway === 'home');

		// ENSURE BOTH TEAMS EXIST BEFORE CREATING matchupAbbrId
		const awayTeamAbr = awayTeam?.team?.abbreviation || 'away';
		const homeTeamAbr = homeTeam?.team?.abbreviation || 'home';

		return {
			id: event.id,
			date: event.date,
			name: event.name,
			shortName: event.shortName,
			matchupAbbrId: `${awayTeamAbr.toLowerCase()}-${homeTeamAbr.toLowerCase()}`, // CREATING matchupAbbrId FROM ABBREVIATIONS
			competitors:
				competitors.map((comp) => {
					const teamData = teamIdToTeamData[comp.team.id];
					const compTeam = {
						team_id: comp.team.id,
						name: comp.team.displayName,
						abbreviation: comp.team.abbreviation,
						shortName: comp.team.shortDisplayName,
						location: comp.team.location,
						color: `#${comp.team.color?.toUpperCase() || ''}`,
						logo: teamData?.logo || comp.team.logo || '',
						teamProfile: teamData?.teamProfile || {},
						identifiers: teamData?.identifiers || [],
						rankings: teamData?.rankings || [],
						links: teamData?.links || [],
					};
					return compTeam;
				}) || [],
			status: {
				type: event.status?.type?.name || 'STATUS_SCHEDULED',
			},
		};
	});

	// RETURN THE TEAMS AND ENRICHED MATCHUPS
	return { teams: teamsData, matchups: enrichedMatchups };
};

const setSimplifiedTeamsData = (data) => {
	const collection = [];

	data.map((team) => {
		const model = {
			espn_id: team.teamProfile.espn_id,
			espn_uid: team.teamProfile.espn_uid,
			espn_slug: team.teamProfile.espn_slug,
			logo: team.teamProfile.logo,
			name: team.teamProfile.teamProfile.name,
			nickname: team.teamProfile.teamProfile.nickname,
			abbreviation: team.teamProfile.teamProfile.abbreviation,
		};

		collection.push(model);
	});

	cfbTeamsRef_simple.push(collection);
};

// FUNCTION TO GET CFB EVENTS AND ADD PICKS
export const getCFBEventsWithPicks = async () => {
	try {
		// Get matchups (events) from ESPN API
		const { matchups } = await getCFBTeams();

		// Scrape picks from various sources (oddstrader, covers, pickswise, dunkelIndex)
		const formattedPicks = await getFormattedCFBPicks();

		// console.log('Formatted Picks:', formattedPicks);

		// Combine matchups with their respective picks
		const eventsWithPicks = matchups.map((event) => {
			const eventTeams = event.competitors.map((team) => ({
				name: team.name.toLowerCase(),
				abbreviation: team.abbreviation.toLowerCase(),
			}));

			const picks = {};

			// Loop through each source and match the picks with the teams in the event
			for (const source in formattedPicks) {
				picks[source] = formattedPicks[source].filter((pick) => {
					const pickTeams = pick.matchupAbbrId.split('-');

					// Check if both teams in the pick match any of the event teams
					return pickTeams.every((pickTeamAbbr) => eventTeams.some((eventTeam) => eventTeam.abbreviation === pickTeamAbbr));
				});
			}

			// Return the event with picks combined
			return {
				...event,
				picks,
			};
		});

		return eventsWithPicks;
	} catch (error) {
		console.error('Error in getCFBEventsWithPicks:', error);
		throw error;
	}
};

const getTeamAbbreviation = (teamName) => {
	// console.log('teamName', teamName);
	// console.log('cfbTeamsRef_simple \n', cfbTeamsRef_simple);

	// Normalize the teamName for consistent comparison
	const normalizedTeamName = teamName.toLowerCase().replace(/\s+/g, '');

	// Find the team where the identifiers include the normalizedTeamName
	const matchedTeam = cfbTeamsRef.find((team) => {
		const identifiers = team?.teamProfile?.identifiers;
		if (identifiers) {
			// Normalize each identifier before comparison
			return identifiers.some((identifier) => identifier.toLowerCase().replace(/\s+/g, '') === normalizedTeamName);
		}
		return false;
	});

	if (matchedTeam) {
		// Return the abbreviation from the matched team's profile
		return matchedTeam.teamProfile.teamProfile.abbreviation;
	} else {
		// If no match is found, return the normalized teamName
		return normalizedTeamName;
	}
};

// NEW START

export const getCFBEvents = async () => {
	const cfbEvents = new Map();
	const cfbTeams = [];
	const espnCFBTeams = await getCFBTeamsFromESPN();
	const picks = await getCFBPicks();

	console.log('***PICKS \n', picks);

	// const sourcePicks = {
	// 	covers: [picks.covers[0]],
	// 	oddstrader: [picks.oddstrader[0]],
	// 	pickswise: [picks.pickswise[0]],
	// 	dunketIndex: [picks.dunkelIndex[0]],
	// };
	// console.log(sourcePicks);

	// CONSTRUCT CFB EVENTS
	for (const team of espnCFBTeams) {
		const event = team.nextEvent[0];
		if (event && event.id && !cfbEvents.has(event.id)) {
			const homeTeam = event.competitions[0].competitors[0];
			const awayTeam = event.competitions[0].competitors[1];
			const eventStatus = await getCFBEventStatus(event);

			// Fetch home team details
			const homeTeamResponse = await fetch(
				`https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${homeTeam.id}`
			);
			const homeTeamDetails = await homeTeamResponse.json();

			// Fetch away team details
			const awayTeamResponse = await fetch(
				`https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${awayTeam.id}`
			);
			const awayTeamDetails = await awayTeamResponse.json();

			const eventModel = {
				...event,
				eventStatus: eventStatus,
				matchupAbbrId: `${awayTeam.team.abbreviation.toLowerCase()}-${homeTeam.team.abbreviation.toLowerCase()}`,
				homeTeam: {
					...homeTeam.team,
					rank: homeTeam.curatedRank?.current || 'Unranked',
					record: homeTeam.record || 'No record available',
					espnAPI_teamInfo: `https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${homeTeam.id}`,
					details: homeTeamDetails.team,
				},
				awayTeam: {
					...awayTeam.team,
					rank: awayTeam.curatedRank?.current || 'Unranked',
					record: awayTeam.record || 'No record available',
					espnAPI_teamInfo: `https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${awayTeam.id}`,
					details: awayTeamDetails.team,
				},
				identifiers: team.teamProfile.identifiers,
				// competitors: event.competitions[0].competitors,
				picks: {
					covers: [],
					oddstrader: [],
					pickswise: [],
					dunkelIndex: [],
				},
			};

			cfbEvents.set(event.id, eventModel);
		}
		cfbTeams.push(team.teamProfile);
	}

	// const eventsWithPicks = cfbEvents.forEach((event) => {
	// 	const matchedEventPicks = {
	// 		covers: picks.covers.forEach((pick) => {
	// 			return pick;
	// 		}),
	// 		oddstrader: picks.oddstrader.forEach((pick) => {
	// 			return pick;
	// 		}),
	// 		pickswise: picks.pickswise.forEach((pick) => {
	// 			return pick;
	// 		}),
	// 		dunkelIndex: picks.dunkelIndex.forEach((pick) => {
	// 			return pick;
	// 		}),
	// 	}
	// })

	return { events: Array.from(cfbEvents.values()), teams: cfbTeams };
};

const getCFBTeamsFromESPN = async () => {
	const api = espnAPI.cfb.rankings;
	const response = await fetch(api);
	const data = await response.json();

	// COLLECTION ARRAY TO STORE UNIQUE TEAM IDS
	const teamIds = [];
	const teamIdToRankings = {};

	(data.rankings || []).forEach((ranking) => {
		(ranking.ranks || []).forEach(({ team = {} }) => {
			if (!teamIds.includes(team.id)) {
				teamIds.push(team.id);
			}
			if (!teamIdToRankings[team.id]) {
				teamIdToRankings[team.id] = [];
			}
			teamIdToRankings[team.id].push(ranking);
		});
	});

	// FETCH TEAM INFORMATION FOR EACH TEAM ID
	const teams = await Promise.all(
		teamIds.map(async (teamId) => {
			const teamInfoAPI = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${teamId}`;
			const response = await fetch(teamInfoAPI);
			const teamData = await response.json();

			const team = teamData.team || {};
			const teamProfile = {
				espn_slug: `${team.location} ${team.name}`
					.toLowerCase()
					.replace(/[^\w\s-]/g, '')
					.replace(/\s+/g, '-'),
				espn_id: team.id || '',
				espn_uid: team.uid || '',
				espn_teamInformationAPI: teamInfoAPI,
				name: team.name || '',
				logo: team.logos?.[0]?.href || '',
				teamProfile: {
					name: team.name || '',
					nickname: team.nickname || '',
					abbreviation: team.abbreviation || '',
					location: team.location || '',
					color: `#${team.color?.toUpperCase() || ''}`,
					logos: (team.logos || []).reduce((acc, logo) => {
						if (logo.rel.includes('default')) {
							acc.default = logo.href;
						} else if (logo.rel.includes('dark')) {
							acc.dark = logo.href;
						} else if (logo.rel.includes('light')) {
							acc.light = logo.href;
						} else {
							acc.other = acc.other || [];
							acc.other.push(logo.href);
						}
						return acc;
					}, {}),
				},
				// rankings: teamIdToRankings[team.id] || [],
				identifiers: [
					team.abbreviation.toLowerCase(),
					team.name.toLowerCase(),
					team.nickname.toLowerCase(),
					team.location.toLowerCase(),
				],
				links: (team.links || []).map(({ href = '', text = '' }) => ({
					url: href,
					label: text,
				})),
			};

			return { teamProfile, nextEvent: teamData.team?.nextEvent || [] };
		})
	);

	return teams;
};

const getCFBEventStatus = async (event) => {
	const eventDetailsFromESPN = `http://site.api.espn.com/apis/site/v2/sports/football/college-football/summary?event=${event.id}`;
	const res = await fetch(eventDetailsFromESPN);
	const data = await res.json();
	const eventStatus = data.header?.competitions[0]?.status || '';

	const status = {
		name: data.header?.competitions[0]?.status?.type?.name || '',
		state: data.header?.competitions[0]?.status?.type?.state || '',
		shortDetail: data.header?.competitions[0]?.status?.type?.shortDetail || '',
		completed: data.header?.competitions[0]?.status?.type?.completed || false,
	};

	return status;
};

const getCFBPicks = async (event) => {
	const data = await getFormattedCFBPicks();

	return data;
};

// SCRAPE AND FORMAT CFB PICKS
const getFormattedCFBPicks = async () => {
	try {
		const filters = ['oddstrader', 'covers', 'pickswise', 'dunkelIndex'];
		const scrapedPicks = await scrapeService.scrapeCFBPicks(filters);

		const formattedPicks = {
			oddstrader: scrapedPicks.oddstrader.map((pick) => formatPickData(pick, 'Oddstrader')).filter(Boolean),
			covers: scrapedPicks.covers.map((pick) => formatPickData(pick, 'Covers')).filter(Boolean),
			pickswise: scrapedPicks.pickswise.map((pick) => formatPickData(pick, 'Pickswise')).filter(Boolean),
			dunkelIndex: scrapedPicks.dunkelIndex.map((pick) => formatPickData(pick, 'DunkelIndex')).filter(Boolean),
		};

		return formattedPicks;
	} catch (error) {
		console.error('Error formatting CFB picks:', error);
		throw error;
	}
};

// HELPER FUNCTION TO FORMAT PICK DATA
const formatPickData = (pick, sourceName) => {
	let awayTeamAbbr, homeTeamAbbr, pickValue, pickDescription;

	switch (sourceName.toLowerCase()) {
		case 'oddstrader':
			awayTeamAbbr = getTeamAbbreviation(pick.team1.name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2.name);
			pickValue = `Spread ${pick.team1.consensusSpread} | Total ${pick.team1.consensusTotal}`;
			pickDescription = `Consensus Spread: ${pick.consensusSpread}, Total: ${pick.consensusTotal}`;
			break;

		case 'covers':
			awayTeamAbbr = getTeamAbbreviation(pick.team1Name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2Name);
			pickValue = pick.pickValueText || 'No value';
			pickDescription = pick.pickDescription || 'No description';
			break;

		case 'pickswise':
			awayTeamAbbr = getTeamAbbreviation(pick.team1Name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2Name);
			pickValue = `${pick.pickOutcome} (${pick.pickConfidence} confidence)`;
			pickDescription = pick.reasoning || 'No description';
			break;

		case 'dunkelindex':
			awayTeamAbbr = getTeamAbbreviation(pick.team1Name);
			homeTeamAbbr = getTeamAbbreviation(pick.team2Name);
			pickValue = pick.predictions.dunkel?.rawText || 'No value';
			pickDescription = pick.featuredPickInformation || 'No description';
			break;

		default:
			awayTeamAbbr = 'n/a';
			homeTeamAbbr = 'n/a';
			pickValue = 'No value';
			pickDescription = 'No description';
			break;
	}

	// If team abbreviations couldn't be found, skip this pick
	if (!awayTeamAbbr || !homeTeamAbbr) {
		console.warn(`Team abbreviations not found for pick:`, pick);
		return null;
	}

	// Return formatted pick
	return {
		id: uuidv4(),
		// matchupAbbrId: `${awayTeamAbbr}-${homeTeamAbbr}`,
		matchupAbbrId: `${awayTeamAbbr}-${homeTeamAbbr}`,
		pickSourceName: sourceName,
		pickSourceURL: pick.pickSourceURL || '',
		pickType: pick.pickType || 'N/A',
		pickValue,
		pickDescription,
		...pick,
	};
};

const matchEventToPicks = (events, picks) => {
	events.forEach((event) => {
		console.log(event);
		const eventAwayTeam = event.awayTeam.abbreviation.toLowerCase();
		const eventHomeTeam = event.homeTeam.abbreviation.toLowerCase();
		const eventMatchupId = `${eventAwayTeam}-${eventHomeTeam}`;

		// Loop through each pick source
		Object.keys(picks).forEach((source) => {
			picks[source].forEach((pick) => {
				// 1. Attempt to match using matchupAbbrId directly
				if (pick.matchupAbbrId === eventMatchupId) {
					event.picks[source].push(pick);
				}
				// 2. Fallback matching using team abbreviations
				else if (
					(pick.matchupAbbrId.includes(eventAwayTeam) && pick.matchupAbbrId.includes(eventHomeTeam)) ||
					(pick.matchupAbbrId.includes(event.awayTeam.location.toLowerCase()) &&
						pick.matchupAbbrId.includes(event.homeTeam.location.toLowerCase()))
				) {
					event.picks[source].push(pick);
				}
			});
		});
	});

	return events;
};

const getCFBTeams2 = async () => {
	const espnCollegeFootballRankings = 'https://site.api.espn.com/apis/site/v2/sports/football/college-football/rankings';
	const response = await fetch(espnCollegeFootballRankings);
	const data = await response.json();

	const cfbTeams = [];

	// Helper function to fetch team details
	const fetchTeamDetails = async (teamId) => {
		const teamInfoAPI = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/teams/${teamId}`;
		const response = await fetch(teamInfoAPI);
		const teamData = await response.json();
		return teamData;
	};

	// Loop through all rankings to get team details
	for (const ranking of data.rankings || []) {
		for (const rank of ranking.ranks || []) {
			const team = rank.team;
			const teamId = team.id;

			// Fetch the team details
			const teamDetails = await fetchTeamDetails(teamId);

			// Add team profile data to the teams array
			const teamProfile = {
				espn_id: team.id,
				espn_uid: team.uid,
				name: team.name,
				abbreviation: team.abbreviation,
				location: team.location,
				nickname: team.nickname,
				teamDetails, // Return full details for events usage
			};
			cfbTeams.push(teamProfile);
		}
	}

	return cfbTeams;
};

const getCFBEvents2 = async (cfbTeams) => {
	const cfbEvents = new Map();

	// Process each team to get their events
	for (const teamProfile of cfbTeams) {
		const events = teamProfile.teamDetails.team?.nextEvent || [];

		// Process each event for the team
		for (const event of events) {
			if (event && event.id && !cfbEvents.has(event.id)) {
				const awayTeam = event.competitions[0].competitors.find((c) => c.homeAway === 'away');
				const homeTeam = event.competitions[0].competitors.find((c) => c.homeAway === 'home');

				// You might have a function for event status like `getCFBEventStatus`
				const eventStatus = await getCFBEventStatus(event);

				// Construct the event model
				const eventModel = {
					...event,
					eventStatus: eventStatus,
					matchupAbbrId: `${awayTeam.team.abbreviation.toLowerCase()}-${homeTeam.team.abbreviation.toLowerCase()}`,
					awayTeam: awayTeam.team,
					homeTeam: homeTeam.team,
					identifiers: [
						teamProfile.abbreviation.toLowerCase(),
						teamProfile.name.toLowerCase(),
						teamProfile.nickname.toLowerCase(),
						teamProfile.location.toLowerCase(),
					],
				};

				// Add event to the map
				cfbEvents.set(event.id, eventModel);
			}
		}
	}

	return Array.from(cfbEvents.values());
};

// const teams2 = await getCFBTeams2();
// const events2 = await getCFBEvents2(teams2);

// console.log('CFB TEAMS _teams', teams2);
// console.log('CFB EVENTS _events', events2);
