import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Countdown = ({ data, countdown = true, text = true, countdownClassName = '', textClassName = '' }) => {
	const getCountdown = (eventDate) => {
		const now = moment();
		const targetDate = moment(eventDate);
		const duration = moment.duration(targetDate.diff(now));

		if (duration.asSeconds() <= 0) {
			return 'Event started';
		}

		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();

		const parts = [];

		if (days > 0) parts.push(`${days}d`);
		if (hours > 0) parts.push(`${hours}h`);
		parts.push(`${minutes}m`);

		// ONLY SHOW SECONDS IF TIME IS LESS THAN 2 HOURS
		if (hours < 12 && seconds > 0) {
			parts.push(`${seconds}s`);
		}

		return parts.join(' ');
	};

	const [countdownValue, setCountdownValue] = useState(getCountdown(data));

	useEffect(() => {
		const interval = setInterval(() => {
			setCountdownValue(getCountdown(data));
		}, 1000);

		return () => clearInterval(interval);
	}, [data]);

	const formattedTime = moment(data).format('ddd, MMM Do, h:mm A');

	return (
		<>
			{countdown && <span className={countdownClassName}>{countdownValue}</span>}
			{text && <span className={textClassName}>{formattedTime}</span>}
		</>
	);
};

export default Countdown;
