// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import moment from 'moment';
// COMPONENTS
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Row, Col, Card, Button, Modal, Form, ButtonGroup, InputGroup } from 'react-bootstrap';
import LoadingOverlay from '../components/LoadingOverlay';
import Countdown from '../components/Countdown';
// ASSETS
import {
	TbShirtSport,
	MdSportsFootball,
	TbShirtFilled,
	TbBallAmericanFootball,
	TbStar,
	TbStarFilled,
	TbCircle,
	TbCircleFilled,
	TbMinus,
	TbHandClick,
} from 'react-icons/tb';
// HELPERS & SERVICES
import * as espnHelpers from '../helper/espnHelpers';
import * as scrapeService from '../services/scrapeService';
import * as cfbService from '../services/cfbService';

const Homepage = (props) => {
	const { appInfo, user } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [events, setEvents] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [selectedLeague, setSelectedLeague] = useState('NFL');
	const [filters, setFilters] = useState({
		team: '',
		status: '',
	});
	const [scrappedData, setScrappedData] = useState({
		oddstrader: [],
		covers: [],
		pickswise: [],
		dunkelIndex: [],
	});
	const [matchedPicks, setMatchedPicks] = useState([]);
	const CACHE_DURATION = 600000; // 10 MINUTES
	const [timestamp, setTimestamp] = useState(null);
	const [showDescriptions, setShowDescriptions] = useState(false);

	const [formattedNFLEvents, setFormattedNFLEvents] = useState([]);
	const [formattedEvents, setFormattedEvents] = useState([]);

	const [showSavedEvents, setShowSavedEvents] = useState(false);

	// NFL
	const [savedNFLPicks, setSavedNFLPicks] = useState([]);
	// CFB
	const [savedCFBPicks, setSavedCFBPicks] = useState([]);

	// TEST MODAL
	const [showTestModal, setShowTestModal] = useState(false);
	const [cfbTeams, setCfbTeams] = useState([]);

	const logos = {
		oddstrader: 'https://otcdn.virginia.us-east-1.oddstrader.com/OT-LogoWhiteGreen.svg',
		covers: 'https://img.covers.com/covers/header_v2/header-logo.svg',
		pickswise:
			'https://images.pickswise.com/WztLS2YuOy4uDXu2VAoHeMxHmRcozSe9ai0UZ5vkjnA/resize:fill:260:40:0/gravity:ce/quality:100/aHR0cHM6Ly93d3cucGlja3N3aXNlLmNvbS93cC1jb250ZW50L29mZmxvYWQvdXBsb2Fkcy8yMDIxLzA3LzIzMDc0MTQ1L1BpY2tzd2lzZS0yY29sb3ItbG9nby5wbmc',
		dunkelIndex: 'https://www.dunkelindex.com/resources/front/images/footer-logo.png',
		nfl: 'https://static.www.nfl.com/image/upload/v1554321393/league/nvfr7ogywskqrfaiu38m.svg',
		cfb: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/NCAA_logo.svg',
	};

	const links = {
		oddstrader: {
			home: 'https://oddstrader.com',
			nfl: {
				odds: 'https://oddstrader.com/nfl',
				picks: 'https://oddstrader.com/nfl/picks',
			},
			cfb: {
				odds: 'https://oddstrader.com/ncaa-college-football',
				picks: 'https://oddstrader.com/ncaa-college-football/picks',
			},
		},
		covers: {
			home: 'https://www.covers.com',
			nfl: {
				odds: 'https://www.covers.com/sport/football/nfl/odds',
				picks: 'https://www.covers.com/picks/nfl',
			},
			cfb: {
				odds: 'https://www.covers.com/sport/football/ncaaf/odds',
				picks: 'https://www.covers.com/picks/ncaaf',
			},
		},
		pickswise: {
			home: 'https://www.pickswise.com',
			nfl: {
				picks: 'https://www.pickswise.com/nfl/picks/',
			},
			cfb: {
				picks: 'https://www.pickswise.com/college-football/picks/',
			},
		},
		dunkelIndex: {
			home: 'https://www.dunkelindex.com',
			picks: 'https://www.dunkelindex.com/picks',
			nflPicks: 'https://www.dunkelindex.com/picks/nfl',
			cfbPicks: 'https://www.dunkelindex.com/picks/fbs',
		},
	};

	// useEffect(() => {
	// 	getFormattedNFLEvents();
	// }, []);

	// Map event status types to filter values
	const mapEventStatusToFilter = (statusType) => {
		switch (statusType) {
			case 'STATUS_SCHEDULED':
				return 'pre';
			case 'STATUS_IN_PROGRESS':
			case 'STATUS_HALFTIME':
			case 'STATUS_DELAYED':
				return 'in';
			case 'STATUS_FINAL':
			case 'STATUS_FULL_TIME':
				return 'post';
			default:
				return '';
		}
	};

	// const getCFBData = async () => {
	// 	const cfbEvents = await cfbService.getCFBEventsWithPicks();
	// 	console.log('CFB EVENTS WITH PICKS', cfbEvents);
	// };

	// FETCH DATA BASED ON SELECTED LEAGUE
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const cacheKey = selectedLeague === 'NFL' ? 'formattedNFLEvents' : 'formattedCFBEvents';

			// Check cached data
			const cachedData = getCache(cacheKey);
			if (cachedData && cachedData.data.length > 0) {
				setFormattedEvents(cachedData.data);
				// setSavedNFLPicks(cachedData.data);
				setTimestamp(cachedData.timestamp);
				setIsLoading(false);
				return;
			}

			try {
				let data = [];
				if (selectedLeague === 'CFB') {
					// data = await cfbService.getCFBEventsWithPicks();
					// console.log('CFB EVENTS \n', data);

					const { events, teams } = await cfbService.getCFBEvents();
					data = events;
					setCfbTeams(teams);
					console.log('***GET CFB EVENTS \n', events);
					console.log('***GET CFB TEAMS \n', teams);

					// const test2 = await cfbService.getESPNEventId();
					// console.log('METHOD FOR API CALLS \n', test2);
				} else {
					data = await scrapeService.getNFLEventsWithPicks();
					console.log('NFL EVENTS \n', data);
				}

				setFormattedEvents(data);
				setCache(cacheKey, { data, timestamp: new Date().getTime() });
				setTimestamp(new Date().getTime());
			} catch (error) {
				console.error('Error fetching events:', error);
				setFormattedEvents([]);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [selectedLeague]);

	// HANDLE LEAGUE CHANGE
	const handleLeagueChange = (league) => {
		setSelectedLeague(league);
		setFormattedEvents([]);
		localStorage.setItem('selectedLeague', league);
	};

	const handleEventClick = (event) => {
		setSelectedEvent(event);
		setShowModal(true);
	};

	const handleStatusFilter = (status) => {
		const updatedFilters = { ...filters, status };
		setFilters(updatedFilters);
		localStorage.setItem('filters', JSON.stringify(updatedFilters));
	};

	// EVENT FILTERING
	const filterEvents = (events) => {
		if (filters.status === '') {
			return events;
		}

		const filteredEvents = events.filter((event) => {
			return event.status.state === filters.status;
		});

		return filteredEvents;
		// if (filters.status === 'ALL') {
		// 	setFilteredEvents(events);
		// } else {
		// 	setFilteredEvents(filteredEvents);
		// }
		// console.log('INITIAL EVENTS \n', events);
		// console.log(`FILTERED EVENTS status ${filters.status}`, filteredEvents);
		// if (!Array.isArray(events)) {
		// 	return [];
		// }

		// // ADD YOUR FILTER LOGIC HERE IF NEEDED
		// return events;
	};

	const handleClickedPick = (pick, e) => {
		e.preventDefault();

		// Handle right-click (context menu)
		if (e.type === 'contextmenu') {
			if (pick.pickSourceURL) {
				window.open(pick.pickSourceURL, '_blank');
			}
			return;
		}

		// Handle left-click
		let updatedPicks;
		if (!savedNFLPicks.some((savedPick) => savedPick.id === pick.id)) {
			updatedPicks = [pick, ...savedNFLPicks];
		} else {
			updatedPicks = savedNFLPicks.filter((savedPick) => savedPick.id !== pick.id);
		}

		setSavedNFLPicks(updatedPicks);
		// setCache('savedNFLPicks', updatedPicks);
	};

	// CACHING LOGIC
	const getCache = (key) => {
		const cache = localStorage.getItem(key);
		if (!cache) return null;

		const parsedCache = JSON.parse(cache);
		const now = new Date().getTime();

		if (now - parsedCache.timestamp > CACHE_DURATION) {
			localStorage.removeItem(key);
			return null;
		}

		return parsedCache;
	};

	const setCache = (key, data) => {
		const cache = {
			data,
			timestamp: new Date().getTime(),
		};
		localStorage.setItem(key, JSON.stringify(cache));
	};

	useEffect(() => {
		if (savedNFLPicks.length > 0) {
			console.log('savedNFLPicks \n', savedNFLPicks);
		}
	});

	return (
		<>
			{isLoading && <LoadingOverlay />}

			<div className='dashboard-page'>
				<Form className='mb-4' style={{ maxWidth: '700px' }}>
					<Form.Group controlId='filterTeam' className='col-sm-12 col-xl-6'>
						<InputGroup className='d-flex flex-column flex-wrap'>
							{/* League Selection Buttons */}
							<ButtonGroup className='flex-wrap mb-2' aria-label='League selection'>
								<Button
									variant={selectedLeague === 'CFB' ? 'dark' : 'outline-secondary'}
									onClick={() => handleLeagueChange('CFB')}
									size='sm'
									className={`${selectedLeague === 'CFB' ? 'bg-dark bg-gradient' : ''} fs-sm px-2`}
									disabled
								>
									CFB
								</Button>
								<Button
									variant={selectedLeague === 'NFL' ? 'dark' : 'outline-secondary'}
									onClick={() => handleLeagueChange('NFL')}
									size='sm'
									className={`${selectedLeague === 'NFL' ? 'bg-dark bg-gradient' : ''} fs-sm px-2`}
								>
									NFL
								</Button>
							</ButtonGroup>

							<ButtonGroup className='flex-wrap mb-2' aria-label='Status filter'>
								<Button
									variant={filters.status === '' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === '' ? 'bg-dark bg-gradient' : ''}`}
								>
									All
								</Button>
								<Button
									variant={filters.status === 'pre' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('pre')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === 'pre' ? 'bg-dark bg-gradient' : ''}`}
								>
									Scheduled
								</Button>
								<Button
									variant={filters.status === 'in' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('in')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === 'in' ? 'bg-dark bg-gradient' : ''}`}
								>
									In Progress
								</Button>
								<Button
									variant={filters.status === 'post' ? 'dark' : 'outline-secondary'}
									onClick={() => handleStatusFilter('post')}
									size='sm'
									className={`fs-sm px-2 ${filters.status === 'post' ? 'bg-dark bg-gradient' : ''}`}
								>
									Completed
								</Button>
								{/* <Button
									variant='primary'
									onClick={handleDownload}
									size='sm'
									className='fs-sm px-2 bg-gradient'
									disabled={isDownloading}
								>
									Download
								</Button> */}
							</ButtonGroup>

							{/* <ButtonGroup className='flex-wrap fit-content' aria-label='Status filter'>
								<Button
									variant={filters.status === '' ? 'dark' : 'outline-secondary'}
									onClick={() => showSavedEvents(true)}
									size='sm'
									className={`fs-sm px-2 ${filters.status === '' ? 'bg-dark bg-gradient' : ''}`}
								>
									Saved
								</Button>
							</ButtonGroup> */}

							<Form.Check
								type='switch'
								id='showDescriptions'
								label='Show Descriptions'
								checked={showDescriptions}
								onChange={() => setShowDescriptions(!showDescriptions)}
								className='mt-3'
							/>
						</InputGroup>
					</Form.Group>
				</Form>

				{timestamp && (
					<div className='mb-3'>
						<p className='text-muted'>Last Fetched: {moment.duration(moment().diff(timestamp)).humanize()} ago</p>
					</div>
				)}

				{selectedLeague === 'CFB' && (
					<ResponsiveMasonry
						columnsCountBreakPoints={{ 300: 1, 400: 2, 900: 3, 1250: 4, 1500: 5 }}
						// className='mb-100'
					>
						<Masonry gutter='20px'>
							{Array.isArray(formattedEvents) &&
								filterEvents(formattedEvents).map((event) => {
									return (
										<Card key={event.id} className='card overflow-hidden bg-light-gradient-animate'>
											<Card.Body className='card-body p-0'>
												<div
													className='card-header d-flex align-items-baseline justify-content-between flex-wrap ugy-2 ugx-1 py-1 px-2'
													style={{
														borderTopLeftRadius: '4px',
														borderTopRightRadius: '4px',
														cursor: 'pointer',
														borderBottom: '1px solid #d3d3d33d',
													}}
													onClick={() => handleEventClick(event)}
												>
													<Countdown
														data={event.date}
														countdown
														text
														countdownClassName='fs-xs fw-600 text-body-secondary lh-normal opacity-90'
														textClassName='fs-xxs text-body-tertiary fw-600 lh-1'
													/>
												</div>

												<div>
													<div className='d-flex flex-wrap justify-content-between ugy-3 p-2'>
														{/* {Array.isArray(event.competitors) &&
															event.competitors
																.sort((a, b) => (a.homeAway === 'away' ? -1 : 1))
																.map((team, index) => (
																	<div key={index} className='d-flex align-items-center mb-1'>
																		<img src={team.team.logos[0].href} width='35' />
																		<div className='d-flex flex-column'>
																			<p className='fs-sm fw-600'>{team.displayName}</p>
																		</div>
																	</div>
																))} */}

														{event.awayTeam && (
															<div className='d-flex align-items-center mb-1 ugy-1'>
																<img src={event.awayTeam?.logos?.[0]?.href} width='35' />
																<div className='d-flex flex-column align-items-start position-relative'>
																	{event.awayTeam?.rank && event.awayTeam?.rank <= 25 && (
																		<p className='fs-xxxs fw-800 lh-1 position-absolute' style={{ top: '-5px', right: '-8px' }}>
																			{event.awayTeam?.rank}
																		</p>
																	)}
																	<p className='fs-sm fw-600 lh-1'>{event.awayTeam?.shortDisplayName}</p>
																	{/* <p className='fs-sm fw-600 lh-1'>{event.awayTeam?.details?.nickname}</p>
																	<p className='fs-xxs text-muted fw-600 lh-1'>{event.awayTeam?.details?.name}</p> */}
																	<p className='fs-xxxs fw-400 lh-1'>{event.awayTeam?.details?.record.items[0].summary}</p>
																</div>
															</div>
														)}

														{event.homeTeam && (
															<div className='d-flex align-items-center mb-1 ugy-1'>
																<div className='d-flex flex-column align-items-end'>
																	<p className='fs-sm fw-600 lh-1'>{event.homeTeam?.shortDisplayName}</p>
																	{/* <p className='fs-sm fw-600 lh-1'>{event.awayTeam?.details?.nickname}</p>
																	<p className='fs-xxs text-muted fw-600 lh-1'>{event.awayTeam?.details?.name}</p> */}
																	<p className='fs-xxxs fw-400 lh-1'>{event.homeTeam?.details?.record.items[0].summary}</p>
																</div>
																<img src={event.homeTeam?.logos?.[0]?.href} width='35' />
															</div>
														)}
													</div>

													{/* Covers Picks */}
													{/* {event.picks.covers?.length > 0 && (
														<div className='pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.covers} className='' alt='Covers logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.covers.map((pick, index) => (
																	<div className='d-flex flex-column' key={index}>
																		<p className='pick'>
																			<span className='pick-label fs-xxs'>{pick.pickType}</span>
																			<span className='pick-value fs-xs'>{pick.pickValue}</span>
																			{showDescriptions && pick.pickDescription && (
																				<span className='text-body-tertiary fs-xs mb-2 lh-normal text-justify'>
																					{pick.pickDescription}
																				</span>
																			)}
																		</p>
																	</div>
																))}
															</div>
														</div>
													)} */}

													{/* {event.picks.dunkelIndex?.length > 0 && (
														<div className='pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.dunkelIndex} className='' alt='Dunkel Index logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.dunkelIndex.map((pick, index) => {
																	// Parse Dunkel Prediction
																	const dunkelMatch = pick.pickValue.dunkelRawText.match(/(\w+)\sWinning\sMargin:\s(-?\d+)/);
																	const dunkelTeam = dunkelMatch ? dunkelMatch[1] : 'N/A';
																	const dunkelMargin = dunkelMatch ? dunkelMatch[2] : 'N/A';
																	const dunkelTotalScore = pick.pickValue.dunkelTotalScore || 'N/A';

																	// Parse Vegas Prediction
																	const vegasMatch = pick.pickValue.vegasRawText.match(/(\w+)\sWinning\sMargin:\s(-?\d+)/);
																	const vegasTeam = vegasMatch ? vegasMatch[1] : 'N/A';
																	const vegasMargin = vegasMatch ? vegasMatch[2] : 'N/A';
																	const vegasTotalScore = pick.pickValue.vegasTotalScore || 'N/A';

																	return (
																		<div className='d-flex flex-column' key={index}>
																			<p className='pick ug-1'>
																				<span className='pick-label fs-xxs lh-normal'>Dunkel</span>
																				<span className='pick-value fs-xs'>{`${dunkelTeam} ${dunkelMargin} | Team ${dunkelTotalScore} pts`}</span>
																			</p>

																			<p className='pick ug-1'>
																				<span className='pick-label fs-xxs lh-normal'>Vegas</span>
																				<span className='pick-value fs-xs'>{`${vegasTeam} ${vegasMargin} | Team ${vegasTotalScore} pts`}</span>
																			</p>
																		</div>
																	);
																})}
															</div>
														</div>
													)} */}

													{/* Oddstrader Picks */}
													{/* {event.picks.oddstrader?.length > 0 && (
														<div className='pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.oddstrader} className='' alt='Oddstrader logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.oddstrader.map((pick, index) => (
																	<div className='d-flex flex-column' key={index}>
																		{pick.pickValue.team1 && (
																			<p className='pick'>
																				<span className='pick-label fs-xxs'>{pick.pickValue.team1.label}</span>
																				<span className='pick-value fs-xs'>{pick.pickValue.team1.value}</span>
																			</p>
																		)}
																		{pick.pickValue.team2 && (
																			<p className='pick'>
																				<span className='pick-label fs-xxs'>{pick.pickValue.team2.label}</span>
																				<span className='pick-value fs-xs'>{pick.pickValue.team2.value}</span>
																			</p>
																		)}
																	</div>
																))}
															</div>
														</div>
													)} */}

													{/* Pickswise Picks */}
													{/* {event.picks.pickswise?.length > 0 && (
														<div className='pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.pickswise} className='' alt='Pickswise logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.pickswise.map((pick, index) => (
																	<div className='d-flex flex-column' key={index}>
																		<p className='pick'>
																			<span className='pick-label fs-xxs'>{pick.pickType}</span>
																			<span className='pick-value fs-xs'>{pick.pickValue.value}</span>
																		</p>
																	</div>
																))}
															</div>
														</div>
													)} */}
												</div>
											</Card.Body>
										</Card>
									);
								})}
						</Masonry>
					</ResponsiveMasonry>
				)}

				{selectedLeague === 'NFL' && (
					<ResponsiveMasonry
						columnsCountBreakPoints={{ 300: 1, 400: 2, 900: 3, 1250: 4, 1500: 5 }}
						// className='mb-100'
					>
						<Masonry gutter='20px'>
							{Array.isArray(formattedEvents) &&
								filterEvents(formattedEvents).map((event) => {
									return (
										<Card key={event.id} className='card overflow-hidden bg-light-gradient-animate'>
											<Card.Body className='card-body p-0'>
												<div
													className='card-header d-flex align-items-baseline justify-content-between flex-wrap ugy-2 ugx-1 py-1 px-2'
													style={{
														borderTopLeftRadius: '4px',
														borderTopRightRadius: '4px',
														cursor: 'pointer',
														borderBottom: '1px solid #d3d3d33d',
														// background: `linear-gradient(45deg, ${event.competitors[1].color}D9 0%, ${event.competitors[0].color}D9 100%)`,
													}}
													// onClick={() => handleEventClick(event)}
												>
													<Countdown
														data={event.date}
														countdown
														text
														countdownClassName='fs-xs fw-600 text-body-secondary lh-normal opacity-90'
														textClassName='fs-xxs text-body-tertiary fw-600 lh-1'
													/>
												</div>

												<div>
													<div className='header p-2'>
														<div className='teams d-flex justify-content-between flex-wrap ugy-3'>
															{/* AWAY TEAM */}
															<div className='away-team d-flex align-items-center mb-1 ugy-1'>
																<img src={event.competitors[1].logo} width='35' alt={`${event.competitors[1].name} logo`} />
																<div className='d-flex flex-column align-items-start position-relative'>
																	<p
																		title={`${event.competitors[1].displayName} Stats`}
																		className='team-name fs-sm fw-600 lh-1'
																		style={{ cursor: 'pointer' }}
																		onClick={() => {
																			const statisticsLink = event.competitors[1].links.find((link) =>
																				link.text.includes('Statistics')
																			);
																			if (statisticsLink) {
																				window.open(statisticsLink.href);
																			}
																		}}
																	>
																		{event.competitors[1].name}
																	</p>
																	<p className='fs-xxxs fw-400 lh-1'>{event.competitors[1].records.overall}</p>
																</div>
															</div>

															{/* HOME TEAM */}
															<div className='home-team d-flex align-items-center mb-1 ugy-1'>
																<div className='d-flex flex-column align-items-end position-relative'>
																	<p
																		title={`${event.competitors[0].displayName} Stats`}
																		className='team-name fs-sm fw-600 lh-1'
																		style={{ cursor: 'pointer' }}
																		onClick={() => {
																			const statisticsLink = event.competitors[0].links.find((link) =>
																				link.text.includes('Statistics')
																			);
																			if (statisticsLink) {
																				window.open(statisticsLink.href);
																			}
																		}}
																	>
																		{event.competitors[0].name}
																	</p>
																	<p className='fs-xxxs fw-400 lh-1'>{event.competitors[0].records.overall}</p>
																</div>
																<img src={event.competitors[0].logo} width='35' alt={`${event.competitors[0].name} logo`} />
															</div>
														</div>
													</div>

													{/* Covers Picks */}
													{event.picks.covers?.length > 0 && (
														<div className='covers pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.covers} className='' alt='Covers logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.covers.map((pick, index) => (
																	<div className='d-flex flex-column' key={index}>
																		<p className='pick'>
																			<span className='pick-label fs-xxs'>{pick.pickType}</span>
																			<span
																				title='Left click to copy. Right click to save.'
																				className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'} ${
																					savedNFLPicks.includes(pick) && 'saved'
																				}`}
																				onClick={(e) => handleClickedPick(pick, e)}
																				onContextMenu={(e) => handleClickedPick(pick, e)}
																			>
																				{pick.pickValue}
																			</span>
																			{showDescriptions && pick.pickDescription && (
																				<span className='text-body-tertiary fs-xs mb-2 lh-normal text-justify'>
																					{pick.pickDescription}
																				</span>
																			)}
																		</p>
																	</div>
																))}
															</div>
														</div>
													)}

													{/* Dunkel Index */}
													{event.picks.dunkelIndex?.length > 0 && (
														<div className='dunkelIndex pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.dunkelIndex} className='' alt='Dunkel Index logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.dunkelIndex.map((pick, index) => {
																	const dunkelMatch = pick.pickValue.dunkelRawText.match(/(\w+)\sWinning\sMargin:\s(-?\d+)/);
																	const dunkelTeam = dunkelMatch ? dunkelMatch[1] : 'N/A';
																	const dunkelMargin = dunkelMatch ? dunkelMatch[2] : 'N/A';
																	const dunkelTotalScore = pick.pickValue.dunkelTotalScore || 'N/A';

																	const vegasMatch = pick.pickValue.vegasRawText.match(/(\w+)\sWinning\sMargin:\s(-?\d+)/);
																	const vegasTeam = vegasMatch ? vegasMatch[1] : 'N/A';
																	const vegasMargin = vegasMatch ? vegasMatch[2] : 'N/A';
																	const vegasTotalScore = pick.pickValue.vegasTotalScore || 'N/A';

																	return (
																		<div className='d-flex flex-column' key={index}>
																			<p className='pick ug-1'>
																				<span className='pick-label fs-xxs lh-normal'>Dunkel</span>
																				<span
																					title={`✅ LEFT-CLICK = TOGGLE SAVE \nℹ️ RIGHT-CLICK = VISIT SOURCE`}
																					className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'} ${
																						savedNFLPicks.includes(pick) && 'saved'
																					}`}
																					onClick={(e) => handleClickedPick(pick, e)}
																					onContextMenu={(e) => handleClickedPick(pick, e)}
																				>
																					{`${dunkelTeam} ${dunkelMargin} | Total ${dunkelTotalScore} pts`}
																				</span>
																			</p>

																			<p className='pick ug-1'>
																				<span className='pick-label fs-xxs lh-normal'>Vegas</span>
																				<span
																					title={`✅ LEFT-CLICK = TOGGLE SAVE \nℹ️ RIGHT-CLICK = VISIT SOURCE`}
																					className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'} ${
																						savedNFLPicks.includes(pick) && 'saved'
																					}`}
																					onClick={(e) => handleClickedPick(pick, e)}
																					onContextMenu={(e) => handleClickedPick(pick, e)}
																				>
																					{`${vegasTeam} ${vegasMargin} | Total ${vegasTotalScore} pts`}
																				</span>
																			</p>
																		</div>
																	);
																})}
															</div>
														</div>
													)}

													{/* Oddstrader Picks */}
													{event.picks.oddstrader?.length > 0 && (
														<div className='oddstrader pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.oddstrader} className='' alt='Oddstrader logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.oddstrader.map((pick, index) => (
																	<div className='d-flex flex-column' key={index}>
																		{pick.pickValue.team1 && (
																			<p className='pick'>
																				<span className='pick-label fs-xxs'>{pick.pickValue.team1.label}</span>
																				<span
																					title={`✅ LEFT-CLICK = TOGGLE SAVE \nℹ️ RIGHT-CLICK = VISIT SOURCE`}
																					className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'} ${
																						savedNFLPicks.includes(pick) && 'saved'
																					}`}
																					onClick={(e) => handleClickedPick(pick, e)}
																					onContextMenu={(e) => handleClickedPick(pick, e)}
																				>
																					{pick.pickValue.team1.value}
																				</span>
																			</p>
																		)}
																		{pick.pickValue.team2 && (
																			<p className='pick'>
																				<span className='pick-label fs-xxs'>{pick.pickValue.team2.label}</span>
																				<span
																					title={`✅ LEFT-CLICK = TOGGLE SAVE \nℹ️ RIGHT-CLICK = VISIT SOURCE`}
																					className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'} ${
																						savedNFLPicks.includes(pick) && 'saved'
																					}`}
																					onClick={(e) => handleClickedPick(pick, e)}
																					onContextMenu={(e) => handleClickedPick(pick, e)}
																				>
																					{pick.pickValue.team2.value}
																				</span>
																			</p>
																		)}
																	</div>
																))}
															</div>
														</div>
													)}

													{/* Pickswise Picks */}
													{event.picks.pickswise?.length > 0 && (
														<div className='pickswise pick-category mb-2'>
															<div className='content-source round'>
																<img src={logos.pickswise} className='' alt='Pickswise logo' />
															</div>
															<div className='pick-container d-flex flex-column'>
																{event.picks.pickswise.map((pick, index) => (
																	<div className='d-flex flex-column' key={index}>
																		<div className='pick'>
																			<span className='pick-label fs-xxs'>{pick.pickType}</span>
																			<span
																				title={`✅ LEFT-CLICK = TOGGLE SAVE \nℹ️ RIGHT-CLICK = VISIT SOURCE`}
																				className={`pick-value fs-xs position-relative overflow-hidden ${
																					pick.pickSourceURL && 'has-source-url'
																				} ${savedNFLPicks.includes(pick) && 'saved'}`}
																				onClick={(e) => handleClickedPick(pick, e)}
																				onContextMenu={(e) => handleClickedPick(pick, e)}
																				style={{ paddingBottom: '3px' }}
																			>
																				<span className='position-relative' style={{ zIndex: '2' }}>
																					{pick.pickValue.value}
																				</span>

																				{pick.pickConfidence && (
																					<div
																						className='pick-confidence position-absolute'
																						style={{
																							bottom: '0px',
																							left: '50%',
																							zIndex: '1',
																							transform: 'translateX(-50%)',
																							rowGap: '0px',
																							width: '100%',
																							opacity: '0.90',
																						}}
																					>
																						<div
																							className='d-flex align-items-center'
																							style={{ width: '100%', gap: '0px', columnGap: '2px' }}
																						>
																							{pick.pickConfidence.map((star, index) =>
																								star === '*' ? (
																									<div
																										key={`filled-${index}`}
																										className='roundest'
																										style={{
																											flexGrow: 1,
																											height: '3px',
																											// backgroundColor: '#E52017',
																											backgroundColor: '#00A2DF',
																										}}
																									/>
																								) : (
																									<div
																										key={`empty-${index}`}
																										className='rounded'
																										style={{
																											flexGrow: 1,
																											height: '3px',
																											backgroundColor: '#CBD5E1',
																										}}
																									/>
																								)
																							)}
																						</div>
																					</div>
																				)}
																			</span>
																		</div>
																	</div>
																))}
															</div>
														</div>
													)}

													<div
														className='card-footer px-2 py-1'
														style={{
															borderTop: '1px solid rgba(211, 211, 211, 0.24)',
															// backgroundFilter: 'blur(2px)',
															// backgroundColor: '#f7f7f7',
															// background: `linear-gradient(45deg, ${event.competitors[1].color}D9 0%, ${event.competitors[0].color}D9 100%)`,
														}}
													>
														{/* EVENT LINKS */}
														{event.links?.length > 0 && (
															<div className='event-links d-flex align-items-center justify-content-center flex-wrap ug-1'>
																{event.links.map((link, index) => (
																	<button
																		key={index}
																		// variant='light'
																		size='sm'
																		onClick={() => window.open(link.href)}
																		className='d-flex align-items-center justify-content-center text-body-secondary fw-700 bg-gradient text-uppercase lh-normal flex-grow-1'
																		style={{
																			padding: '1px 1px',
																			opacity: '0.85',
																			fontSize: '0.70rem',
																		}}
																	>
																		{link.text}
																	</button>
																))}
															</div>
														)}
													</div>
												</div>
											</Card.Body>
										</Card>
									);
								})}
						</Masonry>
					</ResponsiveMasonry>
				)}

				{selectedEvent && (
					<Modal
						show={showSavedEvents}
						onHide={() => {
							showSavedEvents(false);
							// setSelectedEvent(null);
						}}
					>
						<Modal.Header closeButton className='border-0 p-2 pb-0' />
						<Modal.Body className='pt-0'>
							{/* {savedNFLPicks && ( */}
							{/* Covers Picks */}
							{/* {savedNFLPicks.pickSourceName === 'Covers' && (
								<div className='covers pick-category mb-2'>
									<div className='content-source round'>
										<img src={logos.covers} className='' alt='Covers logo' />
									</div>
									<div className='pick-container d-flex flex-column'>
										{event.picks.covers.map((pick, index) => (
											<div className='d-flex flex-column' key={index}>
												<p className='pick'>
													<span className='pick-label fs-xxs'>{pick.pickType}</span>
													<span
														className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'} ${
															savedNFLPicks.includes(pick) && 'saved'
														}`}
														// onClick={() => window.open(pick.pickSourceURL)}
														onClick={() => {
															if (!savedNFLPicks.includes(pick)) {
																setSavedNFLPicks([pick, ...savedNFLPicks]);
															} else {
																setSavedNFLPicks(savedNFLPicks.filter((savedPick) => savedPick !== pick));
															}
														}}
													>
														{pick.pickValue}
													</span>
													{showDescriptions && pick.pickDescription && (
														<span className='text-body-tertiary fs-xs mb-2 lh-normal text-justify'>{pick.pickDescription}</span>
													)}
												</p>
											</div>
										))}
									</div>
								</div>
							)} */}
							{/* {savedNFLPicks.pickSourceName === 'Dunkel Index' && (
								<div className='dunkelIndex pick-category mb-2'>
									<div className='content-source round'>
										<img src={logos.dunkelIndex} className='' alt='Dunkel Index logo' />
									</div>
									<div className='pick-container d-flex flex-column'>
										{event.picks.dunkelIndex.map((pick, index) => {
											// Parse Dunkel Prediction
											const dunkelMatch = pick.pickValue.dunkelRawText.match(/(\w+)\sWinning\sMargin:\s(-?\d+)/);
											const dunkelTeam = dunkelMatch ? dunkelMatch[1] : 'N/A';
											const dunkelMargin = dunkelMatch ? dunkelMatch[2] : 'N/A';
											const dunkelTotalScore = pick.pickValue.dunkelTotalScore || 'N/A';

											// Parse Vegas Prediction
											const vegasMatch = pick.pickValue.vegasRawText.match(/(\w+)\sWinning\sMargin:\s(-?\d+)/);
											const vegasTeam = vegasMatch ? vegasMatch[1] : 'N/A';
											const vegasMargin = vegasMatch ? vegasMatch[2] : 'N/A';
											const vegasTotalScore = pick.pickValue.vegasTotalScore || 'N/A';

											return (
												<div className='d-flex flex-column' key={index}>
													<p className='pick ug-1'>
														<span className='pick-label fs-xxs lh-normal'>Dunkel</span>
														<span
															className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'}`}
															onClick={() => window.open(pick.pickSourceURL)}
														>
															{`${dunkelTeam} ${dunkelMargin} | Team ${dunkelTotalScore} pts`}
														</span>
													</p>

													<p className='pick ug-1'>
														<span className='pick-label fs-xxs lh-normal'>Vegas</span>
														<span
															className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'}`}
															onClick={() => window.open(pick.pickSourceURL)}
														>
															{`${vegasTeam} ${vegasMargin} | Team ${vegasTotalScore} pts`}
														</span>
													</p>
												</div>
											);
										})}
									</div>
								</div>
							)} */}
							{/* Oddstrader Picks */}
							{/* {savedNFLPicks.pickSourceName === 'Oddstrader' && (
								<div className='oddstrader pick-category mb-2'>
									<div className='content-source round'>
										<img src={logos.oddstrader} className='' alt='Oddstrader logo' />
									</div>
									<div className='pick-container d-flex flex-column'>
										{event.picks.oddstrader.map((pick, index) => (
											<div className='d-flex flex-column' key={index}>
												{pick.pickValue.team1 && (
													<p className='pick'>
														<span className='pick-label fs-xxs'>{pick.pickValue.team1.label}</span>
														<span
															className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'}`}
															onClick={() => window.open(pick.pickSourceURL)}
														>
															{pick.pickValue.team1.value}
														</span>
													</p>
												)}
												{pick.pickValue.team2 && (
													<p className='pick'>
														<span className='pick-label fs-xxs'>{pick.pickValue.team2.label}</span>
														<span
															className={`pick-value fs-xs ${pick.pickSourceURL && 'has-source-url'}`}
															onClick={() => window.open(pick.pickSourceURL)}
														>
															{pick.pickValue.team2.value}
														</span>
													</p>
												)}
											</div>
										))}
									</div>
								</div>
							)} */}
							{/* Pickswise Picks */}
							{/* {savedNFLPicks.pickSourceName === 'Pickswise' && (
								<div className='pickswise pick-category mb-2'>
									<div className='content-source round'>
										<img src={logos.pickswise} className='' alt='Pickswise logo' />
									</div>
									<div className='pick-container d-flex flex-column'>
										{event.picks.pickswise.map((pick, index) => (
											<div className='d-flex flex-column' key={index}>
												<div className='pick'>
													<span className='pick-label fs-xxs'>{pick.pickType}</span>
													<span
														className={`pick-value fs-xs position-relative overflow-hidden ${
															pick.pickSourceURL && 'has-source-url'
														}`}
														onClick={() => window.open(pick.pickSourceURL)}
														style={{ paddingBottom: '3px' }}
													>
														<span className='position-relative' style={{ zIndex: '2' }}>
															{pick.pickValue.value}
														</span>

														{pick.pickConfidence && (
															<div
																className='pick-confidence position-absolute'
																style={{
																	bottom: '0px',
																	left: '50%',
																	zIndex: '1',
																	transform: 'translateX(-50%)',
																	rowGap: '0px',
																	width: '100%',
																	opacity: '0.90',
																}}
															>
																<div
																	className='d-flex align-items-center'
																	style={{ width: '100%', gap: '0px', columnGap: '1px' }}
																>
																	{pick.pickConfidence.map((star, index) =>
																		star === '*' ? (
																			<div
																				key={`filled-${index}`}
																				className='roundest'
																				style={{
																					flexGrow: 1,
																					height: '3px',
																					backgroundColor: '#E52017',
																				}}
																			/>
																		) : (
																			<div
																				key={`empty-${index}`}
																				className='rounded'
																				style={{
																					flexGrow: 1,
																					height: '3px',
																					backgroundColor: '#CBD5E1',
																				}}
																			/>
																		)
																	)}
																</div>
															</div>
														)}
													</span>
												</div>
											</div>
										))}
									</div>
								</div>
							)} */}
							{/* // )} */}
							{/* {savedCFBPicks.length > 0 && } */}
							{/* <div className='d-flex flex-wrap ug-3 mb-2'>
								{selectedEvent.competitors.map((team, index) => (
									<div key={index} className='d-flex align-items-center ug-1'>
										<img src={team.logo} alt={team.name} width='40' />
										<div className='d-flex flex-column'>
											<p className='fs-xs fw-600'>
												{team.name}
												<span className='fs-xxxs text-secondary-emphasis fw-400'> {team.records.overall}</span>
											</p>
										</div>
									</div>
								))}
							</div>

							<div className='mt-3'>
								{matchedPicks && (
									<div>
										{Object.keys(matchedPicks).map((source) =>
											matchedPicks[source].length > 0 ? (
												<div key={source} className='mb-3'>
													<h5 className='mb-2'>
														<img src={logos[source]} alt={`${source} logo`} className='content-source round' />
													</h5>
													{matchedPicks[source].map((pick, index) => (
														<div className='d-flex flex-column' key={index}>
															{source === 'covers' && (
																<p className='pick d-flex align-items-center flex-wrap ug-1 fs-sm fw-500 px-1 py-1 bg-body-secondary text-body-secondary lh-normal round'>
																	<span className='pick-label fs-xxs'>{pick.pickType.replace('Pick - ', '')}</span>
																	<span>{pick.pickValueText}</span>
																</p>
															)}

															{source === 'oddstrader' && (
																<>
																	<p className='pick d-flex align-items-center flex-wrap ug-1 fs-sm fw-500 px-1 py-1 bg-body-secondary text-body-secondary lh-normal round'>
																		<span className='pick-label fs-xxs'>AI Prediction</span>
																		<span className=''>{`${pick.aiPrediction}`}</span>
																	</p>
																	<p className='pick d-flex align-items-center flex-wrap ug-1 fs-sm fw-500 px-1 py-1 bg-body-secondary text-body-secondary lh-normal round'>
																		<span className='pick-label fs-xxs'>Consensus Spread</span>
																		<span className=''>{`${pick.consensusSpread}`}</span>
																	</p>
																	<p className='pick d-flex align-items-center flex-wrap ug-1 fs-sm fw-500 px-1 py-1 bg-body-secondary text-body-secondary lh-normal round'>
																		<span className='pick-label fs-xxs'>Consensus Total</span>
																		<span className=''>{`${pick.consensusTotal}`}</span>
																	</p>
																</>
															)}

															{source === 'pickswise' && (
																<p className='pick fs-sm fw-500 px-1 bg-body-secondary text-body-secondary lh-normal round'>
																	Outcome: {pick.pickOutcome}
																</p>
															)}

															{source === 'dunkelIndex' && (
																<p className='pick fs-sm fw-500 px-1 bg-body-secondary text-body-secondary lh-normal round'>
																	{pick.featuredPickInformation && pick.featuredPickInformation}
																</p>
															)}
														</div>
													))}
												</div>
											) : null
										)}
									</div>
								)}
							</div> */}
						</Modal.Body>
					</Modal>
				)}
			</div>
		</>
	);
};

export default Homepage;
